import {graphql} from "gatsby";
import React, {FC, Fragment} from "react";
import Code from "../components/code";
import Layout from "../components/layout/layout-sidebar";
import {SidebarItemProps} from "../components/layout/sidebar";
import Seo from "../components/seo";
import {FnDoc, FnDocParam, FnDocSignature, FnDocType, FnModule} from "../types";

interface FnTypeProps {
    type: FnDocType;
}

const UnionFnType: FC<FnTypeProps> = ({type}) => {
    if (!type.union || !type.union.length) {
        return null;
    }
    return (
        <>
            {type.union.map((currentType, idx) => (
                <Fragment key={idx}>
                    {idx > 0 ? ` | ` : ``}
                    <FnType type={currentType} />
                </Fragment>
            ))}
        </>
    );
};

const FnType: FC<FnTypeProps> = ({type}) => {
    if (type.union) {
        return <UnionFnType type={type} />;
    }
    return (
        <>
            {type.link ? <a href={type.link}>{type.name}</a> : type.name}
            {type.typeArguments?.length ? (
                <>
                    {`<`}
                    {type.typeArguments.map((typeArg, idx) => (
                        <Fragment key={idx}>
                            {idx > 0 ? `, ` : ``}
                            <FnType type={typeArg} />
                        </Fragment>
                    ))}
                    {`>`}
                </>
            ) : null}
            {Array.from({length: type.arrayDepth}, () => `[]`)}
        </>
    );
};

interface FnParamProps {
    param: FnDocParam;
    className?: string;
}

const FnParam: FC<FnParamProps> = ({param, className}) => (
    <span className={className}>
        {param.name && (
            <>
                {param.name}
                {`: `}
            </>
        )}
        <FnType type={param.type} />
    </span>
);

interface FnSignatureProps {
    fnName: string;
    signature: FnDocSignature;
}

const FnSignature: FC<FnSignatureProps> = ({fnName, signature}) => (
    <code className="block rounded-none bg-gray-400 p-4 text-gray-900">
        <span>{fnName}</span>
        {signature.typeParams && signature.typeParams.length ? (
            <>
                {`<`}
                {signature.typeParams.map((type, idx) => (
                    <Fragment key={idx}>
                        {idx > 0 ? `, ` : ``}
                        <FnType type={type} />
                    </Fragment>
                ))}
                {`>`}
            </>
        ) : null}
        {`(`}
        {signature.params.map((param, idx) => (
            <Fragment key={param.name}>
                {idx > 0 ? `, ` : ``}
                <FnParam param={param} />
            </Fragment>
        ))}
        {`): `}
        <FnParam param={signature.returns} />
    </code>
);

interface FnBlockProps {
    fn: FnDoc;
}

const FnBlock: FC<FnBlockProps> = ({fn}) => (
    <section id={fn.name} className="shadow-lg bg-transparent mb-16 text-gray-900 rounded-md overflow-hidden">
        <h3 className=" bg-gray-900 text-gray-200 text-xl font-sans m-0 p-4">{fn.name}</h3>
        {fn.signatures.map((signature, idx) => (
            <div key={idx}>
                <FnSignature fnName={fn.name} signature={signature} />
                <div className="p-4 bg-gray-300">
                    <p dangerouslySetInnerHTML={{__html: signature.description}} />
                    <div className="my-4">
                        <label className="text-gray-500 font-bold">Arguments</label>
                        <ul className="list-square list-outside ml-5">
                            {signature.params.map((param) => (
                                <li key={param.name}>
                                    <FnParam param={param} className="font-mono text-sm text-teal-darker font-bold" />
                                    <span className="ml-2">{param.description}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="my-4">
                        <label className="block text-gray-500 font-bold">Returns</label>
                        <FnParam param={signature.returns} className="font-mono text-sm text-teal-darker font-bold" />
                        <span className="ml-2">{signature.returns.description}</span>
                    </div>
                </div>
                <Code language="javascript" code={signature.exampleCode} />
            </div>
        ))}
    </section>
);

interface ApiDocsPageProps {
    data: {
        allFnModule: {
            nodes: FnModule[];
        };
    };
}

const ApiDocsPage: FC<ApiDocsPageProps> = ({data}) => (
    <Layout sidebarItems={parseSidebarItems(data.allFnModule.nodes)}>
        <Seo
            title="API Docs"
            description="Detailed API documentation with typed function parameters, return types and code examples."
        />
        <div className="bg-gray-800">
            {data.allFnModule.nodes.map((fnModule) => (
                <section key={fnModule.path} id={fnModule.path} className="m-8">
                    <h2 className="text-3xl text-gray-500 mb-8 py-4 border-b border-t border-solid border-gray-600">
                        <span className="font-bold">{fnModule.path}</span>{" "}
                        <span className="font-regular text-gray-600">module</span>
                    </h2>
                    {fnModule.functions.map((fn) => (
                        <FnBlock key={fn.name} fn={fn} />
                    ))}
                </section>
            ))}
        </div>
    </Layout>
);

export default ApiDocsPage;

export const query = graphql`
    query FnDocQuery {
        allFnModule {
            nodes {
                name
                functions {
                    name
                    signatures {
                        description
                        exampleCode
                        params {
                            description
                            name
                            type {
                                link
                                name
                                arrayDepth
                                union {
                                    name
                                    arrayDepth
                                }
                                typeArguments {
                                    description
                                    name
                                    arrayDepth
                                    union {
                                        name
                                        arrayDepth
                                    }
                                    typeArguments {
                                        description
                                        name
                                        arrayDepth
                                        union {
                                            name
                                            arrayDepth
                                        }
                                    }
                                }
                            }
                        }
                        typeParams {
                            description
                            link
                            name
                            arrayDepth
                            union {
                                name
                                arrayDepth
                            }
                            typeArguments {
                                description
                                name
                                arrayDepth
                                union {
                                    name
                                    arrayDepth
                                }
                                typeArguments {
                                    description
                                    name
                                    arrayDepth
                                    union {
                                        name
                                        arrayDepth
                                    }
                                }
                            }
                        }
                        returns {
                            description
                            type {
                                description
                                link
                                name
                                arrayDepth
                                union {
                                    name
                                    arrayDepth
                                }
                                typeArguments {
                                    description
                                    name
                                    arrayDepth
                                    union {
                                        name
                                        arrayDepth
                                    }
                                    typeArguments {
                                        description
                                        name
                                        arrayDepth
                                        union {
                                            name
                                            arrayDepth
                                        }
                                    }
                                }
                            }
                        }
                        since
                    }
                }
                path
            }
        }
    }
`;

const parseSidebarItems = (modules: FnModule[]): SidebarItemProps[] => {
    return modules.map((module) => ({
        label: `${module.name}`,
        link: `#${module.name}`,
        items: module.functions.map((fn) => ({
            label: `${fn.name}`,
            link: `#${fn.name}`,
        })),
    }));
};
